<script setup lang="ts">
import Columnist from 'avris-columnist';

import type { Post } from '~/src/blog/metadata.ts';

const props = defineProps<{
    posts: string[] | Post[];
    details?: boolean;
}>();

const { data: postsFull } = useAsyncData(`posts-${JSON.stringify(props.posts)}`, async () => {
    if (!props.posts.length) {
        return [];
    }
    if (typeof props.posts[0] === 'object') {
        return props.posts as Post[];
    }
    return await $fetch('/api/blog', {
        params: {
            slugs: props.posts,
        },
    });
});

const entries = useTemplateRef<HTMLDivElement>('entries');
onMounted(async () => {
    if (entries.value) {
        const columnist = new Columnist(entries.value);
        columnist.start();
    }
});
</script>

<template>
    <div ref="entries" class="columnist-wall row">
        <div v-for="post in postsFull" :key="post.slug" class="columnist-column col-12 col-sm-6 col-md-4 mb-3">
            <BlogEntry :post :details />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .columnist-wall > .columnist-column {
        transition: margin-top .2s ease-in-out;
    }
    .post-title {
        text-wrap: balance;
    }
</style>
