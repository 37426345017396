<script setup lang="ts">

</script>

<template>
    <div v-if="$route.query.error" class="container pt-md-4">
        <div class="alert alert-danger">
            <Icon v="heart-broken" />
            <T>user.login.oauthError</T>
        </div>
    </div>
</template>
