<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

import useConfig from '~/composables/useConfig.ts';
import type { Post } from '~/src/blog/metadata.ts';

const props = defineProps<{
    post: Post;
    details?: boolean;
}>();

const config = useConfig();

const link = computed((): RouteLocationRaw => {
    const shortcuts = config.blog?.shortcuts ?? {};
    const keepFullPath = config.blog?.keepFullPath ?? [];
    const shortcut = Object.entries(shortcuts)
        .find(([_, slug]) => slug === props.post.slug);
    return shortcut !== undefined && !keepFullPath.includes(props.post.slug)
        ? `/${shortcut[0]}`
        : { name: 'blogEntry', params: { slug: props.post.slug } };
});
</script>

<template>
    <div class="card shadow">
        <nuxt-link v-if="post.hero" :to="link">
            <img :src="post.hero.src" :class="['w-100', post.hero.class]" :alt="post.hero.alt" loading="lazy">
        </nuxt-link>
        <nuxt-link :to="link" class="card-body text-center h4 p-3 mb-0 post-title">
            <Spelling :text="post.title" />
        </nuxt-link>
        <div v-if="details" class="card-footer small">
            <ul class="list-inline mb-0">
                <li class="list-inline-item small">
                    <Icon v="calendar" />
                    {{ post.date }}
                </li>
                <li v-for="author in post.authors" class="list-inline-item">
                    <nuxt-link v-if="author.startsWith('@')" :to="`/${author}`" class="badge bg-light text-dark border">
                        <Icon v="collective-logo.svg" class="invertible" />
                        {{ author }}
                    </nuxt-link>
                    <span v-else class="badge bg-light text-dark border">
                        {{ author }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>
