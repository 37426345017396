<script setup lang="ts">
import useConfig from '~/composables/useConfig.ts';
import { getSocialLinks } from '~/src/contact.ts';
import { groupBy } from '~/src/helpers.ts';

const config = useConfig();
const socials = groupBy([...getSocialLinks(config)], (l) => l.group);
</script>

<template>
    <section v-if="Object.keys(socials).length">
        <h2 class="mb-3">
            <Icon v="hashtag" />
            <T>links.social</T>
        </h2>
        <ul class="list-unstyled">
            <template v-for="(groupLinks, group) in socials">
                <li v-if="$t(`contact.groups.${group}`)">
                    <p class="h5">
                        <T>contact.groups.{{ group }}</T><T>quotation.colon</T>
                    </p>
                </li>
                <LinkEntry v-for="link in groupLinks" :key="link.url" :link="link" />
            </template>
        </ul>
    </section>
</template>
