<script setup lang="ts">
import type { Day } from '~/src/calendar/helpers.ts';

defineProps<{
    day: Day;
    inline?: boolean;
}>();

const DateLocal = useLocaleComponent('calendar', 'DateLocal');
</script>

<template>
    <span>
        <Spelling :text="$t('calendar.date', { year: day.year, date: $t(`calendar.dates.${day.month}`, { day: day.day }) })" />
        <DateLocal :day="day" :inline="inline" />
    </span>
</template>
